<template>
  <v-main>

    <v-container
        id="reset-password-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-lock-reset"
          class="px-5 py-3"
          ref="form"
      >

        <v-card-text v-if="errors.length">
          <v-alert color="error"
                   outlined
                   dark
                   :key="error.id"
                   show variant="danger"
                   v-for="error in errors">
            {{ error }}
          </v-alert>
        </v-card-text>

        <v-card-text v-if="tokenGenerated">
          <v-alert color="success"
                               dark
                               outlined>
            <p>A password reset email has been sent to the email address you provided if the address exists in our system.
              Please check your email and visit the secure link included to complete your password reset. The email may be in your spam folder as it is system generated.</p>

            <p>The secure link generated must be used within 24 hours.</p>
          </v-alert>
        </v-card-text>

        <v-card-text v-if="success">
          <v-alert color="success"
                   dark
                   outlined>
            Your password has been reset successfully. Please <router-link :to="{name: 'login'}">click here to log in</router-link> to your account with your new password.
          </v-alert>
        </v-card-text>

        <v-card-text
            v-else-if="!token && !tokenGenerated">

          <v-form ref="generateResetPasswordForm"
                  v-model="valid"
                  @submit.prevent="validateGenerateResetPassword"
                  lazy-validation
          >
            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="emailAddress"
                    label="Email Address"
                    type="email"
                    validate-on-blur
                    :rules="rules.required"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <div class="my-2">
                  <v-btn
                      color="primary"
                      dark
                      type="submit"
                  >
                    Submit
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>

        </v-card-text>


        <v-card-text v-else-if="token">

          <v-form ref="resetPasswordForm"
                  v-model="valid"
                  lazy-validation
                  @submit.prevent="validateResetPassword"
          >

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="emailAddress"
                    label="Email Address"
                    type="email"
                    validate-on-blur
                    :rules="rules.required"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="password"
                    label="New Password"
                    type="password"
                    validate-on-blur
                    :rules="rules.password"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="confirmPassword"
                    label="Re-Enter New Password"
                    type="password"
                    :rules="rules.confirmPassword"
                ></v-text-field>
              </v-col>
            </v-row>


            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <div class="my-2">
                  <v-btn
                      color="primary"
                      dark
                      type="submit"
                  >
                    Submit
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12">
                Trouble resetting you password? <a href="#" @click.prevent="generateNewToken">Generate new reset password request.</a>
              </v-col>
            </v-row>

          </v-form>

        </v-card-text>

      </base-material-card>

    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import validation from '@/utils/validation'
import constants from '@/utils/constants'
import {mapGetters} from "vuex";

export default {
  name: 'CoreResetPasswordView',

  created() {
    this.token = this.$route.query.token
    this.emailAddress = this.$route.query.emailAddress
  },

  computed: {
    ...mapGetters({
      getUserId: 'getUserId'
    })
  },

  methods: {
    validateGenerateResetPassword () {
      this.errors = []
      if(this.$refs.generateResetPasswordForm.validate()) {
        this.generateResetPassword()
      }
    },
    validateResetPassword () {
      this.errors = []
      if(this.$refs.resetPasswordForm.validate()) {
        this.resetPassword()
      }
    },
    generateResetPassword() {
      this.$gtag.event('generate_reset_password')
      let payload = {
        emailAddress: this.emailAddress
      }
      api
          .generateResetPassword(payload)
          .then(response => {
            console.log(response)
            this.tokenGenerated = true
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    resetPassword() {
      this.$gtag.event('reset_password')
      this.success = false
      let payload = {
        emailAddress: this.emailAddress,
        token: this.token,
        password: this.password
      }
      api
          .resetPassword(payload)
          .then(response => {
            if (response.status === 200) {
              this.success = true
            }
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    generateNewToken() {
      this.token = null
      this.tokenGenerated = false
    },
    handleError(err) {
      let errorMessage = api.parseResponseError(err, true)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      valid: false,
      errors: [],
      emailAddress: null,
      token: null,
      tokenGenerated: false,
      password: null,
      confirmPassword: null,
      success: null,
      rules: {
        required: [
          v => validation.isEmailAddress(v) || 'must be a valid email address'
        ],
        password: [
          v => (v && v.length >= constants.PASSWORD_MIN_LENGTH) || `must have a minimum length of ${constants.PASSWORD_MIN_LENGTH}`
        ],
        confirmPassword: [
          v => (this.password === this.confirmPassword) || 'password confirmation must match new password'
        ]
      }
    }
  }
}
</script>
